<template>
  <header class="header-container">
    <div class="sign">
      <a target="_blank" href="https://www.cnknowledge.com/index">
        <img
          style="cursor: pointer; width: 70px; margin-right: 20px"
          v-lazy="require('@/assets/imgs/zwzsw.png')"
          alt=""
        />
      </a>
      <router-link to="/"
        ><img :src="require('@/assets/imgs/bjlogo.png')" alt=""
      /></router-link>
      <div class="go-back">
        <span class="back" @click="goBack">
          <svg
            t="1625708753604"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1146"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M896 544H193.3312a32 32 0 1 1 0-64H896a32 32 0 0 1 0 64z"
              fill="#fff"
              p-id="1147"
            ></path>
            <path
              d="M426.5984 798.72a31.8976 31.8976 0 0 1-22.6304-9.3696L126.8736 512 403.968 234.9056a32 32 0 0 1 45.2608 45.2608L217.3952 512l231.8336 231.8336A32 32 0 0 1 426.5984 798.72z"
              fill="#fff"
              p-id="1148"
            ></path>
          </svg>
        </span>
        <span class="title" @click.stop="isDown = !isDown"
          >{{ $route.query.title }}发布</span
        >
        <span class="down" @click.stop="isDown = !isDown">
          <svg
            t="1625709218276"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1948"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M508.025406 655.446718c-14.45307 0-28.183486-5.781228-39.023289-15.898376l-231.249118-231.249118c-10.117149-10.117149-10.117149-26.015526 0-36.132675s26.015526-10.117149 36.132675 0l231.249118 231.249118c2.16796 2.16796 4.335921 2.16796 5.781228 0l231.971771-231.971771c10.117149-10.117149 26.015526-10.117149 35.410021 0 10.117149 10.117149 10.117149 26.015526 0 36.132674l-231.971771 231.971772c-9.394495 10.117149-23.124912 15.898377-38.300635 15.898376z"
              p-id="1949"
              fill="#fff"
            ></path>
          </svg>
        </span>
        <ul class="down-list" v-if="isDown">
          <li v-for="v in downLists" :key="v.id" @click="goForm(v)">
            {{ v.name }}发布
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!token" class="user">
      <span @click="login(1)" class="login">登录</span>
      <span @click="login(0)" class="register">注册</span>
    </div>
    <div class="user" v-else>
      <Dropdown @on-click="userClick">
        <img
          v-if="userInfo.headPhoto"
          v-lazy="
            userInfo.headPhoto
              ? $global.imgUrl + userInfo.headPhoto
              : '@/assets/img/login/4.png'
          "
          alt=""
        />
        <img v-else src="@/assets/img/login/4.png" alt="" />
        <span class="name">{{
          userInfo.user.customerType === 1
            ? userInfo.account.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
            : userInfo.user.customerType === 2
            ? userInfo.name
            : userInfo.companyName
        }}</span>
        <DropdownMenu slot="list">
          <DropdownItem name="goUserCenter">用户中心</DropdownItem>
          <DropdownItem name="logOut">退出</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <Modal
      :scrollable="true"
      v-model="$store.state.alert"
      width="400px"
      class-name="my-center-modal"
      :footer-hide="true"
      @on-visible-change="visibleChange"
    >
      <div class="content">
        <Top :sonUser="sonUser" @cahngeAct="cahngeAct" :act="act"></Top>
        <Contents
          :sonUser="sonUser"
          @cahngeAct="cahngeAct"
          @changeAlert="changeAlert"
          :act="act"
          :flag="flag"
        ></Contents>
        <Footers
          @SetsonUser="SetsonUser"
          :sonUser="sonUser"
          :act="act"
        ></Footers>
      </div>
    </Modal>
  </header>
</template>

<script>
import { mapState } from "vuex";
import { logOut } from "../../../apis/login";
import Top from "../../main/components/top.vue";
import Contents from "../../main/components/contents.vue";
import Footers from "../../main/components/footers.vue";

export default {
  components: {
    Top,
    Contents,
    Footers,
  },
  data() {
    return {
      sonUser: 0,
      act: 1,
      downList: [
        {
          id: 10,
          name: "资讯",
          path: "/informationForm",
        },
        {
          id: 11,
          name: "论文",
          path: "/paperForm",
        },
        {
          id: 62,
          name: "专著",
          path: "/monographForm",
        },
        {
          id: 63,
          name: "会议成果",
          path: "/meetingForm",
        },
        {
          id: 64,
          name: "专利",
          path: "/patentform",
        },
        {
          id: 65,
          name: "科技报告",
          path: "/reportform",
        },
        {
          id: 66,
          name: "标准",
          path: "/standardform",
        },
        {
          id: 67,
          name: "其他成果",
          path: "/otherform",
        },
      ],
      downLists: [],
      isDown: false,
      flag: false,
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
  },
  watch: {
    $route: {
      handler(newV) {
        console.log(newV, "newV");
        this.downLists = this.downList.filter(
          (item) => item.name !== newV.query.title
        );
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    visibleChange(e) {
      this.flag = e;
    },
    goBack() {
      this.$router.push("/user");
    },
    goForm(item) {
      this.$router.push({
        path: item.path,
        query: {
          title: item.name,
        },
      });
      this.isDown = false;
    },
    handleSearch(e) {
      if (!e) return;
      let url = this.$router.resolve({
        path: "/search",
        query: {
          paramStr: e,
        },
      });
      window.open(url.href, "_blank");
    },
    userClick(e) {
      this[e]();
    },
    goUserCenter() {
      let url = this.$router.resolve({
        path: "/user",
      });
      window.open(url.href, "_blank");
    },
    async logOut() {
      await logOut();
      this.$store.commit("logOut");

      // sessionStorage.setItem("token", "");
      this.$Message.success("退出成功");
      this.$router.push("/");
    },
    //login
    cahngeAct(e) {
      this.act = e;
    },
    changeAlert(e) {
      this.$store.commit("showAlert", e);
    },
    SetsonUser(e) {
      this.sonUser = e;
    },
    login(i) {
      this.act = i;
      // this.alert = true;
      this.$store.commit("showAlert", true);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-dropdown .ivu-select-dropdown {
  top: 60px !important;
}

@media screen and (max-width: 1500px) {
  .header-container {
    padding: 0 10px !important;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
  padding: 0 100px;
  min-width: 1440px;
  height: 99px;
  background-color: #000;

  .go-back {
    display: inline-block;
    position: relative;
    cursor: pointer;

    .title {
      font-size: 20px;
      margin: 0 10px;
      color: #fff;
      vertical-align: middle;
    }

    .back {
      svg {
        width: 20px;
        vertical-align: middle;
      }
    }

    .down {
      svg {
        width: 20px;
        vertical-align: middle;
      }
    }

    .down-list {
      position: absolute;
      left: 65px;
      top: 36px;
      width: 100px;
      background-color: #fff;
      box-shadow: 0px 7px 15px 1px rgb(22 26 100 / 13%);
      z-index: 99999;

      li {
        padding: 5px;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .sign {
    margin-left: 20px;

    a {
      vertical-align: middle;

      img {
        width: 150px;
        height: 40px;
        margin-right: 11px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .user {
    font-size: 18px;
    color: #fefefe;
    cursor: pointer;
    margin-right: 30px;

    img {
      width: 30px;
      height: 30px;
      background: #f9f9f9;
      border: 1px solid #e6e6e6;
      border-radius: 50%;
      float: left;
    }

    .name {
      margin-top: 5px;
      margin-left: 8px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      display: block;
      float: left;
      color: #ffffff;
    }

    .login {
      margin-right: 20px;
    }

    .register {
      display: inline-block;
      text-align: center;
      line-height: 40px;
      width: 120px;
      height: 40px;
      background: #00a4ff;
      border-radius: 20px;
    }
  }
}
</style>
