<template>
  <div class="form-container">
    <Header></Header>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  background: #f9f9f9;
  min-width: 1440px;
  margin: 0 auto;
}
</style>
